<template>
  <template v-if="unit == 1">
    <Adsense
      :key="route"
      class="placement--top"
      ad-client="ca-pub-6102117487539042"
      ad-slot="6966721074"
    />
    <template v-if="false">
      <!-- <div id="bsa-zone_1643744024455-7_123456" class="placement placement--top"></div> -->
      <!-- <Ezoic id="101" /> -->
      <!-- /22404337467,21749048135/whatismyelevation-970x250-Combo-1 -->
      <!-- <div id="div-gpt-ad-1641969340122-0" class="placement placement--top"></div> -->
    </template>
  </template>
  <template v-else-if="unit == 2">
    <Adsense
      :key="route"
      ad-client="ca-pub-6102117487539042"
      ad-slot="1948746426"
    />
    <template v-if="false">
      <!-- <div id="bsa-zone_1643744095546-7_123456" class="placement"></div> -->
      <!-- <Ezoic id="102" /> -->
      <!-- /22404337467,21749048135/whatismyelevation-970x250-Combo-2 -->
      <!-- <div id="div-gpt-ad-1641969373669-0" class="placement"></div> -->
    </template>
  </template>
  <template v-else-if="unit == 3">
    <Adsense
      :key="route"
      class="ad--below-share"
      ad-client="ca-pub-6102117487539042"
      ad-slot="3658187561"
    />
    <template v-if="false">
      <!-- <div id="bsa-zone_1643910416487-1_123456" class="placement"></div> -->
      <!-- <Ezoic id="103" /> -->
      <!-- /22404337467,21749048135/whatismyelevation-970x250-Combo-3 -->
      <!-- <div id="div-gpt-ad-1641969404759-0" class="placement"></div> -->
    </template>
  </template>
  <template v-else-if="unit == 4">
    <template v-if="false">
      <div id="bsa-zone_1643910460241-1_123456" class="placement"></div>
    </template>
  </template>
</template>
<script>
import Adsense from "@/components/Adsense.vue";
// import Ezoic from "@/components/Ezoic.vue";

export default {
  props: ["unit"],
  components: {
    Adsense
  },
  computed: {
    route() {
      return this.$route.path + JSON.stringify(this.$route.query);
    }
  }
};
</script>