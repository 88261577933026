<template>
  <footer class="footer">
    <slot name="above"></slot>
    <div class="section section--about" v-if="includeAbout">
      <h2 class="section__title">{{ title }}</h2>
      <div class="text-block">
        <slot>
          <p>
            This site was designed to help you find the height above sea level
            of your current location, or any point on Earth. When viewed from a
            phone that supports altitude readings, the reading will come
            directly from your device altitude reading and will update
            automatically as you move. It will even work when your phone is
            offline. From a computer your elevation is loaded from our API based
            on your location.
          </p>

          <h2>What is elevation?</h2>
          <p>
            Elevation is a measurement of height above sea level. Elevation
            typically refers to the height of a point on the earth’s surface,
            and not in the air. Altitude is a measurement of an object’s height,
            often referring to your height above the ground (such as in an
            airplane or a satellite). While elevation is often the preferred
            term for the height of your current location, altitude and height
            above sea level are also common.
          </p>

          <h2>How is elevation calculated?</h2>
          <h3>Using an Altimeter</h3>
          <p>
            An altimeter is an instrument used to measure altitude. Altitude is
            measured with an altimeter by calculating differences in atmospheric
            pressure. Inside of an altimeter is a barometer which is a device
            that measures pressure in the air. As you travel upwards, the
            pressure decreases which causes the bellows inside the barometer to
            expand. The bellows then give you an accurate reading of your height
            above sea level regardless of whether you are on the ground, in a
            tree, or in an airplane. Modern smartphones contain a barometer that
            can be used as an altimeter to measure elevation or to calculate the
            number of steps you have climbed.
          </p>
          <p>
            In areas with poor GPS reception an altimeter can be more accurate
            than GPS-based elevation readings and do not require an Internet
            connection.
          </p>

          <AdUnit unit="4" />

          <h3>Using a Digital Elevation Model</h3>
          <p>
            Satellite data from NASA and other public agencies can be combined
            to create what is called a DEM or
            <a
              href="https://www.usgs.gov/faqs/what-are-digital-elevation-models-dems?qt-news_science_products=0#qt-news_science_products"
              >Digital Elevation Model</a
            >
            which is a detailed list of elevation values for points on the
            Earth’s surface. You can then use GPS to fetch the value elevation
            reading in the DEM.
          </p>
          <p>
            When we look at representations of the earth we typically see a
            perfectly circular sphere. But the reality is that the surface of
            the earth is rather corrugated, rugged and irregular. These
            irregularities and variations in the earth’s surface determine the
            acceleration of gravity, which vicariously creates the shape of the
            earth’s liquids. If we were to hypothetically subtract winds, tides
            and other external forces that alter bodies of water we would be
            left with something known as the geoid. It is the shape that the
            Earth’s surface would take if it were all one big ocean without
            winds or tides. The geoid is purely hypothetical though, but it is
            used as a reference for land surveyors to determine elevation based
            on what the sea-level would have been for a given point on Earth
            without land. This is also referred to as “height above mean sea
            level”.
          </p>
          <p>
            The planet earth is extremely large and has radically diverse
            elevations. For example, the highest point on earth is
            <a
              href="https://whatismyelevation.com/en/location/27.98839,86.92521"
              >Mt. Everest</a
            >, which is about 29,000 feet above sea level. The lowest point on
            earth is the Mariana Trench in the Western Pacific, which is
            approximately 35,000 feet below sea level, which is close to seven
            miles deep! The location with the highest elevation in the United
            States is
            <a
              href="https://whatismyelevation.com/en/location/63.0695,-151.0074/Denali--AK-"
              >Denali in Alaska</a
            >. The lowest point is the
            <a
              href="https://whatismyelevation.com/map/36.236122131347656,-116.81718444824219/Badwater-Basin--CA-"
              >Badwater Basin</a
            >, 281ft below sea level.
          </p>

          <h2>How do you find your elevation?</h2>
          <p>
            Using this website you can find out your elevation no matter where
            you are. How does this site determine your precise elevation? The
            site receives GPS data from your phone. This satellite technology is
            also used to determine your precise location and help you navigate
            in apps like Google Maps.
          </p>
          <p>
            Your phone acts as a receiver for satellite data. The time it takes
            for each of the satellite signals to reach the receiver are measured
            in relation to each other and to the speed of light. Because each of
            the satellites is in a different place, the three signals can be
            used to estimate a three dimensional position and can determine your
            position on the Earth to an extremely high degree of accuracy.
          </p>
          <p>
            Using this site you can determine elevation for any location in the
            world, whether you are there or not. Simply enter an address
            anywhere in the world and you will be given its elevation in either
            feet or meters.
          </p>

          <h3>Web vs phone elevation</h3>
          <p>
            When you use the site on a mobile device with a built-in altimeter,
            your elevation is determined by your phone’s built-in altitude
            reading by default (this will show up as “according to your phone”).
            This method may be less accurate when you need the exact elevation
            for your location, but has the advantage of giving you real-time
            altitude updates as you move. This also allows you to bookmark our
            site to use when you are not connected to the Internet.
          </p>
          <p>
            When visiting from a desktop computer, or when searching for an
            address, your elevation is determined by your GPS coordinates based
            on satellite data.
          </p>

          <h3>How to share your elevation</h3>
          <p>
            Just click “Share Link” to send someone a link to view the elevation
            of your location, or click on the Facebook or Twitter buttons to
            copy a link directly into those sites. Note that using this feature
            will share the coordinates of your exact location.
          </p>

          <p>
            We also have a “Share Image” feature showing your elevation on a map
            to customize and post to your Instagram or Facebook story, or saved
            for future reference.
          </p>

          <h2>{{ $t("distance-converter-title") }}</h2>
          <DistanceConverter />
          <p>{{ $t("distance-converter-description") }}</p>

          <!-- <p
            class="note"
          >Note: When you buy through links on our site, we may earn an affiliate commission.</p> -->
        </slot>
        <p v-if="enableCustomApi">
          <router-link :to="pathForLocale({ path: '/api' })"
            >Powered by the What is My Elevation API</router-link
          >
        </p>
      </div>
    </div>
    <div class="section section--navigation">
      <nav>
        <ul>
          <slot name="nav">
            <li>
              <router-link :to="pathForLocale({ path: '/' })">Home</router-link>
            </li>
            <li>
              <a @click="openMap">{{ $t("elevation-map") }}</a>
            </li>
            <li>
              <router-link :to="pathForLocale({ path: '/atlas' })"
                >Elevation Atlas</router-link
              >
            </li>
            <li v-if="enableCustomApi">
              <router-link :to="pathForLocale({ path: '/api' })"
                >API</router-link
              >
            </li>
          </slot>
        </ul>
      </nav>
    </div>
    <div class="wherewhat">
      <header>
        <h2 class="wherewhat-title">
          <a href="https://wherewhat.co/">
            <span class="highlight">Where</span> What
          </a>
        </h2>
        <p class="wherewhat-description">Learn more about where you are.</p>
      </header>
      <section class="site-list">
        <div class="site site--elevation" v-if="elevation">
          <router-link :to="pathForLocale({ path: '/' })">
            <h3><span class="highlight">What</span> is my elevation?</h3>
            <p>
              Find the elevation of your current location, or any point on
              Earth.
            </p>
          </router-link>
        </div>
        <div class="site site--address" v-if="address">
          <router-link :to="pathForLocale({ path: '/what-is-my-address' })">
            <h3><span class="highlight">Where</span> am I?</h3>
            <p>
              Find the address of your current location. It also will also show
              you the GPS coordinates of your current location (latitude and
              longitude).
            </p>
          </router-link>
        </div>
        <div class="site site--fast" v-if="fast">
          <router-link :to="pathForLocale({ path: '/how-fast-am-i-going' })">
            <h3><span class="highlight">How</span> fast am I going?</h3>
            <p>
              Find your current speed, average speed, and compass direction in
              miles per hour, kilometers per hour, or knots.
            </p>
          </router-link>
        </div>
        <div class="site site--far" v-if="far">
          <router-link :to="pathForLocale({ path: '/how-far-is-it' })">
            <h3><span class="highlight">How</span> far is it?</h3>
            <p class="description">
              Find how far it is between two or more locations.
            </p>
          </router-link>
        </div>
        <div class="site site--snow" v-if="snow">
          <router-link :to="pathForLocale({ path: '/snow-totals' })">
            <h3><span class="highlight">Snow</span> Totals</h3>
            <p class="description">
              View the snow accumulation, snow depth, and snowfall for your
              recent winter storms.
            </p>
          </router-link>
        </div>
      </section>
      <footer class="wherewhat-footer">
        <h2>Questions?</h2>
        <p>
          Contact me at
          <a href="mailto:info@wherewhat.co">info@wherewhat.co</a>.
        </p>
      </footer>
    </div>
    <div class="section section--localizations" v-if="localized">
      <ul class="languages">
        <li>
          <router-link :to="pathForLocale({ locale: 'de' })"
            >Deutsch</router-link
          >
        </li>
        <li>
          <router-link :to="pathForLocale({ locale: 'es' })"
            >Español</router-link
          >
        </li>
        <li>
          <router-link :to="pathForLocale({ locale: 'en' })"
            >English</router-link
          >
        </li>
        <li>
          <router-link :to="pathForLocale({ locale: 'fr' })"
            >Français</router-link
          >
        </li>
        <li>
          <router-link :to="pathForLocale({ locale: 'it' })"
            >Italiano</router-link
          >
        </li>
        <li>
          <router-link :to="pathForLocale({ locale: 'pt' })"
            >Português</router-link
          >
        </li>
        <li>
          <router-link :to="pathForLocale({ locale: 'ru' })"
            >Русский</router-link
          >
        </li>
        <li>
          <router-link :to="pathForLocale({ locale: 'ja' })"
            >日本語</router-link
          >
        </li>
        <li>
          <router-link :to="pathForLocale({ locale: 'zh' })">中文</router-link>
        </li>
      </ul>
    </div>
    <div class="section app-settings">
      <a
        v-for="colorScheme in ['light', 'dark', 'auto']"
        :key="colorScheme"
        :class="[
          'setting-button',
          `setting-button--color-scheme-${colorScheme}`,
          currentColorScheme === colorScheme ? 'active' : '',
        ]"
        @click="$store.dispatch('setColorScheme', colorScheme)"
        >{{ $t(`color-scheme-${colorScheme}`) }}</a
      >
    </div>
  </footer>
</template>
<script>
import config from "@/config";
import { pathForLocale, round, encodeUrlTitle } from "@/helpers";
import AdUnit from "@/components/AdUnit.vue";
import DistanceConverter from "@/components/DistanceConverter.vue";

export default {
  components: { AdUnit, DistanceConverter },
  props: {
    title: {
      type: String,
      default: "What is this?"
    },
    includeAbout: {
      type: Boolean,
      default: true
    },
    elevation: {
      type: Boolean,
      default: false
    },
    address: {
      type: Boolean,
      default: true
    },
    fast: {
      type: Boolean,
      default: true
    },
    snow: {
      type: Boolean,
      default: true
    },
    far: {
      type: Boolean,
      default: true
    },
    localized: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    currentColorScheme() {
      return this.$store.state.colorScheme;
    },
    enableCustomApi() {
      return config.ENABLE_CUSTOM_API;
    },
    showAltimeters() {
      return !this.$t("hide-altimeters");
    }
  },
  methods: {
    pathForLocale,
    openMap() {
      const { watchId, location } = this.$store.state;
      if (watchId !== null) {
        this.$router.push({ name: "mapHome" });
      } else {
        this.$router.push({
          name: "map",
          params: {
            latitude: `${round(location.latitude, 5)}`,
            longitude: `${round(location.longitude, 5)}`,
            title: encodeUrlTitle(location.title),
            lang: this.$i18n.locale === "en" ? null : this.$i18n.locale
          }
        });
      }
    }
  }
};
</script>
