<template>
<div class="page--converter">
  <div class="converter-container">
    <div class="converter-box">
      <input
        class="converter-box__value"
        type="number"
        v-model="firstValue"
      />
      <select class="converter-box__unit" v-model="firstUnit">
        <option v-for="(format, index) in formats" :key="index" :value="index">{{format.name}}</option>
      </select>
    </div>
    <div class="equal-sign">=</div>
    <div class="converter-box">
      <input
        class="converter-box__value"
        type="number"
        v-model="secondValue"
        readonly
      />
      <select class="converter-box__unit" v-model="secondUnit">
        <option v-for="(format, index) in formats" :key="index" :value="index">{{format.name}}</option>
      </select>
    </div>
  </div>
  <div class="formula">
    <h3>Formula</h3>
    <p>
    {{firstFormat.name}}
    &times; {{formula}}
    = {{secondFormat.name}}
    </p>
  </div>
</div>
</template>
<script>
import { mapState } from 'vuex';
import { round } from '@/helpers';

export default {
  data() {
    let firstValue = this.$store.state.elevation.value || 100;
    let firstUnit = 1;
    let secondUnit = 0;
    if (this.$store.state.useFeet) {
      firstValue = round((this.$store.state.elevation.value || 100) / 0.3048, 0);
      firstUnit = 0;
      secondUnit = 1;
    }
    return {
      firstValue,
      firstUnit,
      secondUnit,
      isDefault: true,
      formats: [
        {
          shortName: 'ft',
          name: 'Feet',
          multiplier: 0.3048,
        },
        {
          shortName: 'm',
          name: 'Meters',
          multiplier: 1,
        },
        {
          shortName: 'story',
          name: 'Stories',
          multiplier: 4.3,
        },
        {
          shortName: 'fields',
          name: 'Football Fields',
          multiplier: 91.44,
        },
        {
          shortName: 'fields',
          name: 'Eiffel Towers',
          multiplier: 300,
        },
      ],
    };
  },
  computed: {
    ...mapState(['elevation']),
    firstFormat() {
      return this.formats[this.firstUnit];
    },
    secondFormat() {
      return this.formats[this.secondUnit];
    },
    formula() {
      return round(this.secondFormat.multiplier / this.firstFormat.multiplier, 6);
    },
    secondValue() {
      let firstValue = parseFloat(this.firstValue);
      if (Number.isNaN(firstValue)) {
        firstValue = 0;
      }
      return round((firstValue * this.firstFormat.multiplier) / this.secondFormat.multiplier, 1);
    },
  },
  watch: {
    firstValue() {
      this.isDefault = false;
    },
    elevation() {
      if (this.isDefault) {
        this.firstValue = round(this.elevation.value / this.firstFormat.multiplier, 0);

        this.isDefault = false;
      }
    },
  },
};
</script>
